  /* latin-ext */
  @font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 400;
    src: 
        local('Ubuntu Italic'), local('Ubuntu-Italic'),
        url(#{_font(path)+"/Ubuntu-Italic.woff2"}) format('woff2'),
        url(#{_font(path)+"/Ubuntu-Italic.woff"}) format('woff'),
        url(#{_font(path)+"/Ubuntu-Italic.ttf"}) format('ttf')
    ;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 400;
    src:
        local('Ubuntu Italic'), local('Ubuntu-Italic'),
        url(#{_font(path)+"/Ubuntu-Italic.woff2"}) format('woff2'),
        url(#{_font(path)+"/Ubuntu-Italic.woff"}) format('woff'),
        url(#{_font(path)+"/Ubuntu-Italic.ttf"}) format('ttf')
    ;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: 
        local('Ubuntu Regular'), local('Ubuntu-Regular'),
        url(#{_font(path)+"/Ubuntu-Regular.woff2"}) format('woff2'),
        url(#{_font(path)+"/Ubuntu-Regular.woff"}) format('woff'),
        url(#{_font(path)+"/Ubuntu-Regular.ttf"}) format('ttf')
    ;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src:
        local('Ubuntu Regular'), local('Ubuntu-Regular'),
        url(#{_font(path)+"/Ubuntu-Regular.woff2"}) format('woff2'),
        url(#{_font(path)+"/Ubuntu-Regular.woff"}) format('woff')
        url(#{_font(path)+"/Ubuntu-Regular.ttf"}) format('ttf')
    ;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Ubuntu Condensed';
    font-style: normal;
    font-weight: 400;
    src:
        local('Ubuntu Condensed'), local('UbuntuCondensed-Regular'),
        url(#{_font(path)+"/UbuntuCondensed-Regular.woff2"}) format('woff2'),
        url(#{_font(path)+"/UbuntuCondensed-Regular.woff"}) format('woff'),
        url(#{_font(path)+"/UbuntuCondensed-Regular.ttf"}) format('ttf')
    ;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Ubuntu Condensed';
    font-style: normal;
    font-weight: 400;
    src:
        local('Ubuntu Condensed'), local('UbuntuCondensed-Regular'), 
        url(#{_font(path)+"/UbuntuCondensed-Regular.woff2"}) format('woff2'),
        url(#{_font(path)+"/UbuntuCondensed-Regular.woff"}) format('woff'),
        url(#{_font(path)+"/UbuntuCondensed-Regular.ttf"}) format('ttf')
    ;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Ubuntu Mono';
    font-style: normal;
    font-weight: 400;
    src: 
        local('Ubuntu Mono'), local('UbuntuMono-Regular'),
        url(#{_font(path)+"/UbuntuMono-Regular.woff2"}) format('woff2'),
        url(#{_font(path)+"/UbuntuMono-Regular.woff"}) format('woff'),
        url(#{_font(path)+"/UbuntuMono-Regular.ttf"}) format('ttf')
    ;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Ubuntu Mono';
    font-style: normal;
    font-weight: 400;
    src:
        local('Ubuntu Mono'), local('UbuntuMono-Regular'),
        url(#{_font(path)+"/UbuntuMono-Regular.woff2"}) format('woff2'),
        url(#{_font(path)+"/UbuntuMono-Regular.woff"}) format('woff'),
        url(#{_font(path)+"/UbuntuMono-Regular.ttf"}) format('ttf')
    ;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }