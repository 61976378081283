///
/// Editorial by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Header */

	#header {
		padding: 0 0 0 0;
		position: relative;

        div:first-child{
            position: relative;
            @include vendor('display', 'flex');
            /* border-bottom: solid 3px _palette(accent); */

            > * {
                @include vendor('flex', '1');
                margin-bottom: 0;
            }

            .logo {
                border-bottom: 0;
                color: inherit;
                font-family: _font(family-heading);
                font-size: 1.125em;
                order: 2;
                text-align: right;
            }

            .logo h1 {
                font-size: 2.5em;
                margin: 0;
            }

            .icons {
                order:  1;
            }

            @include breakpoint(xlarge) {
                padding-top: 1em;
            }

            @include breakpoint(small) {
                padding-top: 2.5em;

                .logo {
                    font-size: 1.25em;
                    margin: 0;
                }

                .icons {
                    height: (6.25em / 1.25);
                    line-height: (6.25em / 1.25);
                    position: absolute;
                    right: (-0.625em / 1.25);
                    top: 0;
                }
            }
        }
        hr {
            overflow: visible; /* For IE */
            padding: 0;
            border: none;
            height: 15px;
            top: -1em;
            position: relative;
            border: 0;
            border-top: solid 3px _palette(accent);
            border-bottom: solid 5px _palette(accent);
        }
}