@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';
@import 'libs/spinner';
@import 'fontawesome/fontawesome.css';
@import 'fontawesome/fa-solid.css';
@import 'libs/fonts';
/* 
    @import url('https://fonts.googleapis.com/css?family=Ubuntu:400,600,400italic,600italic|Ubuntu+Condensed:400,700|Ubuntu+Mono');
*/
/*
	Editorial by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

	@include skel-breakpoints((
		xlarge: '(max-width: 1680px)',
		large: '(max-width: 1279px)',
		medium: '(max-width: 980px)',
		small: '(max-width: 736px)',
		xsmall: '(max-width: 480px)',
		xxsmall: '(max-width: 360px)',
		xlarge-to-max: '(min-width: 1681px)',
		small-to-xlarge: '(min-width: 481px) and (max-width: 1680px)'
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border',
		grid: ( gutters: 1.5em )
	));

// Base.

	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/section';
	@import 'components/form';
	@import 'components/box';
	@import 'components/icon';
	@import 'components/image';
    @import 'components/list';
	@import 'components/table';
	@import 'components/button';
	@import 'components/mini-posts';
	@import 'components/features';
    @import 'components/posts';
    @import 'components/anker';

// Layout.

	@import 'layout/wrapper';
	@import 'layout/main';
	@import 'layout/sidebar';
	@import 'layout/header';
	@import 'layout/banner';
	@import 'layout/footer';
	@import 'layout/menu';