///
/// Editorial by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Features */

	.features {
		$gutter: _size(gutter);

		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		margin: 0 0 _size(element-margin) ($gutter * -1);
		width: calc(100% + #{$gutter});

		article {
			@include vendor('align-items', 'center');
			@include vendor('display', 'flex');
			margin: 0 0 $gutter $gutter;
			position: relative;
			width: calc(50% - #{$gutter});

			&:nth-child(2n - 1) {
				margin-right: ($gutter * 0.5);
			}

			&:nth-child(2n) {
				margin-left: ($gutter * 0.5);
			}

			&:nth-last-child(1),
			&:nth-last-child(2) {
				margin-bottom: 0;
			}

			.icon {
				@include vendor('flex-grow', '0');
				@include vendor('flex-shrink', '0');
				display: block;
				height: 10em;
				line-height: 10em;
				margin: 0 _size(element-margin) 0 0;
				text-align: center;
				width: 10em;

				&:before {
					color: _palette(accent);
					font-size: 2.75rem;
					position: relative;
					top: 0.05em;
				}

				&:after {
					@include vendor('transform', 'rotate(45deg)');
					border-radius: 0.25rem;
					border: solid 2px _palette(border);
					content: '';
					display: block;
					height: 7em;
					left: 50%;
					margin: -3.5em 0 0 -3.5em;
					position: absolute;
					top: 50%;
					width: 7em;
				}
			}

			.content {
				@include vendor('flex-grow', '1');
				@include vendor('flex-shrink', '1');
				width: 100%;

				> :last-child {
					margin-bottom: 0;
				}
			}
		}

		@include breakpoint(medium) {
			margin: 0 0 _size(element-margin) 0;
			width: 100%;

			article {
				margin: 0 0 $gutter 0;
				width: 100%;

				&:nth-child(2n - 1) {
					margin-right: 0;
				}

				&:nth-child(2n) {
					margin-left: 0;
				}

				&:nth-last-child(1),
				&:nth-last-child(2) {
					margin-bottom: $gutter;
				}

				&:last-child {
					margin-bottom: 0;
				}

				.icon {
					height: 8em;
					line-height: 8em;
					width: 8em;

					&:before {
						font-size: 2.25rem;
					}

					&:after {
						height: 6em;
						margin: -3em 0 0 -3em;
						width: 6em;
					}
				}
			}
		}

		@include breakpoint(xsmall) {
			article {
				@include vendor('flex-direction', 'column');
				@include vendor('align-items', 'flex-start');

				.icon {
					height: 6em;
					line-height: 6em;
					margin: 0 0 (_size(element-margin) * 0.75) 0;
					width: 6em;

					&:before {
						font-size: 1.5rem;
					}

					&:after {
						height: 4em;
						margin: -2em 0 0 -2em;
						width: 4em;
					}
				}
			}
		}

		@include breakpoint(xsmall) {
			article {
				.icon {
					&:before {
						font-size: 1.25rem;
					}
				}
			}
		}
	}